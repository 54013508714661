import invariant from 'tiny-invariant'
import { ChainId } from '../constants'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId
  public readonly address: string
  public readonly projectLink?: string

  public readonly isNative: false = false
  public readonly isToken: true = true

  public constructor(
    chainId: ChainId,
    address: string,
    decimals: number,
    symbol?: string,
    name?: string,
    projectLink?: string
  ) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address)
    this.projectLink = projectLink
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const WETH9: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x1A6a12953d5439e8965d94d3D8452464FBd53e30',
    18,
    'WSDN',
    'Wrapped SDN',
    'https://www.binance.org'
  ),
  [ChainId.ROPSTEN]: new Token(
    ChainId.ROPSTEN,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.RINKEBY]: new Token(
    ChainId.RINKEBY,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.GÖRLI]: new Token(
    ChainId.GÖRLI,
    '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.KOVAN]: new Token(
    ChainId.KOVAN,
    '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.ARBITRUM]: new Token(
    ChainId.ARBITRUM,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.FANTOM]: new Token(
    ChainId.FANTOM,
    '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.MATIC]: new Token(
    ChainId.MATIC,
    '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.OKEX]: new Token(
    ChainId.OKEX,
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.HECO]: new Token(
    ChainId.HECO,
    '0x64FF637fB478863B7468bc97D30a5bF3A428a1fD',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.HARMONY]: new Token(
    ChainId.HARMONY,
    '0x6983D1E6DEf3690C4d616b13597A09e6193EA013',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.XDAI]: new Token(
    ChainId.XDAI,
    '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.MOONRIVER]: new Token(
    ChainId.MOONRIVER,
    '0x98878B06940aE243284CA214f92Bb71a2b032B8A',
    18,
    'WMOVR',
    'Wrapped MOVR'
  ),
}

export const WNATIVE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: WETH9[ChainId.MAINNET],
  [ChainId.ROPSTEN]: WETH9[ChainId.ROPSTEN],
  [ChainId.RINKEBY]: WETH9[ChainId.RINKEBY],
  [ChainId.GÖRLI]: WETH9[ChainId.GÖRLI],
  [ChainId.KOVAN]: WETH9[ChainId.KOVAN],
  [ChainId.FANTOM]: new Token(
    ChainId.FANTOM,
    '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    18,
    'WFTM',
    'Wrapped FTM'
  ),
  [ChainId.MATIC]: new Token(
    ChainId.MATIC,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped Matic'
  ),
  [ChainId.XDAI]: new Token(
    ChainId.XDAI,
    '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
    18,
    'WXDAI',
    'Wrapped xDai'
  ),
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB'
  ),
  [ChainId.ARBITRUM]: WETH9[ChainId.ARBITRUM],
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'WAVAX',
    'Wrapped AVAX'
  ),
  [ChainId.HECO]: new Token(
    ChainId.HECO,
    '0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F',
    18,
    'WHT',
    'Wrapped HT'
  ),
  [ChainId.HARMONY]: new Token(
    ChainId.HARMONY,
    '0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a',
    18,
    'WONE',
    'Wrapped ONE'
  ),
  [ChainId.OKEX]: new Token(
    ChainId.OKEX,
    '0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15',
    18,
    'WOKT',
    'Wrapped OKExChain'
  ),
  [ChainId.MOONRIVER]: new Token(
    ChainId.MOONRIVER,
    '0x98878B06940aE243284CA214f92Bb71a2b032B8A',
    18,
    'WMOVR',
    'Wrapped MOVR'
  ),
}

export const WETH = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x1A6a12953d5439e8965d94d3D8452464FBd53e30',
    18,
    'WSDN',
    'Wrapped SDN',
    'https://www.binance.org'
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xcbd56C6ab4B870eBf07EB0073546E7cd498C4182', //rinkeby 
    18,
    'WSDN',
    'Wrapped SDN',
    'https://www.ethereum.org'
  ),
  [ChainId.ROPSTEN]: new Token(
    ChainId.ROPSTEN,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.RINKEBY]: new Token(
    ChainId.RINKEBY,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.GÖRLI]: new Token(
    ChainId.GÖRLI,
    '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.KOVAN]: new Token(
    ChainId.KOVAN,
    '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.ARBITRUM]: new Token(
    ChainId.ARBITRUM,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH9',
    'Wrapped Ether'
  ),
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.FANTOM]: new Token(
    ChainId.FANTOM,
    '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.MATIC]: new Token(
    ChainId.MATIC,
    '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.OKEX]: new Token(
    ChainId.OKEX,
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.HECO]: new Token(
    ChainId.HECO,
    '0x64FF637fB478863B7468bc97D30a5bF3A428a1fD',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.HARMONY]: new Token(
    ChainId.HARMONY,
    '0x6983D1E6DEf3690C4d616b13597A09e6193EA013',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.XDAI]: new Token(
    ChainId.XDAI,
    '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
    18,
    'WETH',
    'Wrapped Ether'
  ),

  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.MOONRIVER]: new Token(
    ChainId.MOONRIVER,
    '0x98878B06940aE243284CA214f92Bb71a2b032B8A',
    18,
    'WMOVR',
    'Wrapped MOVR'
  ),
}
